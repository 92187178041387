@font-face {
  font-family: "axiformabold";
  src: url("../assets/fonts/kastelov_-_axiforma_bold-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/kastelov_-_axiforma_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "axiformalight";
  src: url("../assets/fonts/kastelov_-_axiforma_light-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/kastelov_-_axiforma_light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "axiformamedium";
  src: url("../assets/fonts/kastelov_-_axiforma_medium-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/kastelov_-_axiforma_medium-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "axiformaregular";
  src: url("../assets/fonts/kastelov_-_axiforma_regular-webfont.woff2")
      format("woff2"),
    url("../assets/fonts/kastelov_-_axiforma_regular-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html iframe {
  z-index: -9999 !important;
}

.MuiAlert-filledSuccess {
  color: #fff !important;
  border: 2px solid #0bb3a2 !important;
  background-color: #0bb3a2 !important;
}
