@import "../../utils/mixins";

.rowSupplier {
  position: relative;
  .flexed {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-basis: 33.3%;
    width: 700px !important;
  }
  .MuiAccordion-root.Mui-expanded {
    margin-bottom: 20 !important;
  }
  p {
    font-family: "Axiforma";
    font-weight: 300;
    font-style: normal;
  }
  h4 {
    text-align: center;
    font-family: "Axiforma";
    font-weight: 300;
    font-style: normal;
    color: #6c6c6c;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    right: -27%;
    top: 0;
    background: url(../../assets/suplier_after.png) no-repeat;
    opacity: 0.2;
    z-index: -1;
  }
  .head {
    border: 1px solid #e2e2e2;
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 40px;
    padding: 50px;
    .headImg {
      display: block;
      max-width: 90%;
      height: auto;
      margin: 0 auto;
    }
    .title {
      display: inline-block;
      color: #00b2a0;
      font-family: "Axiforma";
      font-weight: bold;
      font-style: normal;
      font-weight: bold;
    }
  }
  .text_supplier {
    text-align: center;
    // border-bottom: 1px solid #e2e2e2;
    // margin-bottom: 50px;
    h1 {
      display: block;
      color: #00b2a0;
      font-family: "Axiforma";
      font-weight: bold;
      font-style: normal;
      font-weight: 700;
    }
    p {
      font-family: "Axiforma";
      font-weight: 300;
      font-style: normal;
      color: #a1a1a1;
      font-size: 18px;
      line-height: 28px;
      a {
        letter-spacing: 1.75px;
      }
    }

    .navc {
      margin: 0 0 0 0;
      padding: 0 0 0 0;
      display: block;

      li {
        list-style: none;
        display: inline-block;
        margin: 60px 46px 0 0;
        padding: 0 0 0 0;

        a {
          font-size: 18px;
          color: #666666;
          text-decoration: none;
          .MuiSvgIcon-root {
            color: #00b2a0;
          }
        }
      }
    }

    h4 {
      color: #666666;
      margin-top: 150px;
      margin-bottom: 40px;
    }
  }
  .button_suppliers {
    padding: 30px 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    .back_button {
      display: flex;
      cursor: pointer;
      align-items: center;
      grid-gap: 10px;
      gap: 10px;
      justify-content: center;
      display: inline-block;
      font-size: 21px;
      line-height: 31px;
      color: #fff;
      font-family: "Axiforma";
      font-weight: bold;
      font-style: normal;
      border: 1px solid #11b7a6;
      background-color: #11b7a6;
      border-radius: 5px;
      padding: 7px 30px;
      // margin: 0 30px 0 0;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      text-decoration: none;

      span {
        display: inline-block;
        position: relative;
        padding-right: 10px;
      }
      &:hover {
        border: 1px solid #0bb3a2;
        background-color: #ffffff;
        color: #0bb3a2;
        span {
          left: -5px;
        }
      }
    }
  }
  .square {
    border: 2px solid #c2bdbd;
    width: 140px;
    padding: 25px;
    height: 100px;
    margin: 0 auto 10px auto;
    background-color: #fff;
    border-radius: 15px;
    img {
      max-width: 100%;
      display: block;
      cursor: pointer;
      max-height: 100%;
    }
  }

  .supplier_content {
    margin-bottom: 20px;
  }

  .suppliersGroup {
    margin-bottom: 80px;
    margin-top: 33px;
    // .view_button {
    //   display: block;
    //   padding: 7px 50px;
    //   margin: 0 auto;
    //   width: auto;
    //   border: 1px solid #00b2a0;
    //   border-radius: 10px;
    //   background-color: #00b2a0;
    //   text-decoration: none;
    //   color: #fff;
    //   font-size: 20px;
    //   line-height: 30px;
    //   margin-top: 30px;
    // }
  }
  .MuiSvgIcon-root {
    color: #0bb3a2;
  }
  .MuiAccordionSummary-expandIcon.Mui-expanded .MuiSvgIcon-root {
    color: #fff;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    background-color: #0bb3a2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .MuiAccordion-root.Mui-expanded {
    padding: 0 !important;
    border: 3px solid #0bb3a2 !important;
  }

  .MuiAccordionSummary-root {
    // background-color: #fff !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    padding: 5px 15px !important;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    background-color: #0bb3a2 !important;
    p {
      color: #fff !important;
    }
  }

  .WithStyles\(ForwardRef\(Accordion\)\)-root-1 {
    padding: 0 !important;
  }
  .WithStyles\(ForwardRef\(AccordionSummary\)\)-root-3 {
    min-height: 0px !important;
    margin-bottom: 0px !important;
  }
  .main_features {
    h2 {
      font-size: 60px;
      line-height: 70px;
      font-family: "Axiforma";
      font-weight: bold;
      font-style: normal;
      color: #00b2a0;
    }
    .downloads_content {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;
      .downloads_content_file {
        flex-direction: column;
        display: flex;
        align-items: center;
        // width: 30%;
        width: 175px;
        height: 350px;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;

        @include for-phone-only {
          width: 100%;
        }

        img {
          width: 170px;
          padding-bottom: 20px;
        }
        a {
          font-size: 18px;
          text-align: center;
          font-weight: 300;
          color: #6e6d6d;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          padding-bottom: 20px;
          text-decoration: none;
          text-transform: uppercase;
          // width: 50%;
          @include for-phone-only {
            width: 100%;
          }
        }
        &:hover {
          cursor: pointer;
          img {
            transform: scale(1.05);
          }
          a {
            color: #0bb3a2;
          }
        }
      }

      .division_pdf {
        width: 15%;
        :hover {
          img {
            transform: scale(1.05);
          }
          color: #00b2a0 !important;

          a {
            color: #00b2a0 !important;
          }
        }
        .pdf-image {
          height: 230px;
          display: flex;
          align-items: center;
          justify-content: center;
          :hover {
            color: #00b2a0 !important;

            a {
              color: #00b2a0 !important;
            }
          }
          img {
            transition: all 0.3s ease-in-out;
          }
        }
        a {
          text-decoration: none;
          display: flex;
          text-align: center;
          font-size: 18px;
          font-weight: 300;
          color: #6e6d6d;
          transition: all 0.3s ease-in-out;
          flex-direction: column;
          word-break: break-all;
        }
      }
    }
  }
}
