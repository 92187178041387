.card-product-details-description_contact {
  padding-top: 80px;
  padding-bottom: 50px;
  .flexbox .MuiAccordion-root.Mui-expanded {
    margin-bottom: 25px !important;
  }

  .MuiAccordion-root.Mui-expanded {
    margin-bottom: 25px !important;
  }

  .MuiAccordion-root {
    overflow: hidden;
    // margin-top: 20px;
    // background-color: transparent;
    .WithStyles\(ForwardRef\(AccordionSummary\)\)-root-3 {
      background-color: transparent;
    }
  }
  &-left {
    .flexbox .MuiAccordion-root.Mui-expanded {
      margin-bottom: 25px !important;
    }

    .MuiAccordion-root.Mui-expanded {
      margin-bottom: 25px !important;
    }
    padding-bottom: 20px;
    .MuiSvgIcon-root {
      color: #0bb3a2;
    }
    .MuiAccordionSummary-expandIcon.Mui-expanded .MuiSvgIcon-root {
      color: #fff;
    }
    .MuiAccordionSummary-root.Mui-expanded {
      background-color: #0bb3a2;
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
    }
    .MuiAccordionDetails-root {
      // display: flex;
      // flex-direction: row;
      // justify-content: flex-start;
      background-color: #f6f6f6;
      padding: 15px 15px 0px 15px !important;
      // gap: 10px;
      // overflow-x: hidden !important;
    }
    .properties {
      position: relative;
    }
    .properties::after {
      position: absolute;
      content: "";
      height: 30px;
      width: 23px;
      background: url(../../assets/properties1.png) no-repeat;
      left: 3px;
      top: 0;
    }
    .MuiAccordionSummary-content.Mui-expanded .properties::after {
      background: url(../../assets/properties.png) no-repeat;
    }
    .images {
      position: relative;
      // margin-top: 5px;
    }
    .images::after {
      position: absolute;
      content: "";
      height: 30px;
      width: 23px;
      background: url(../../assets/images.png) no-repeat;
      left: 3px;
      top: 4px;
    }
    .MuiAccordionSummary-content.Mui-expanded .images::after {
      background: url(../../assets/images1.png) no-repeat;
    }
    .descr {
      position: relative;
    }
    .descr::after {
      position: absolute;
      content: "";
      width: 23px;
      height: 30px;
      left: 3px;
      top: 0;
      background: url(../../assets/description.png) no-repeat;
    }
    .MuiAccordionSummary-content.Mui-expanded .descr::after {
      background: url(../../assets/description1.png) no-repeat;
    }
    .MuiTypography-body1 {
      color: #c6c6c6;
      font-family: "Axiforma";
      font-weight: bold;
      font-style: normal;
      font-size: 18px;
      line-height: 28px;
      padding-left: 50px;
      margin-top: 5px;
    }

    .MuiAccordionSummary-root.Mui-expanded .MuiTypography-body1 {
      color: #fff !important;
    }
    .accordian_imgs {
      padding-right: 20px;
    }
    .description.Mui-expanded {
      color: white;
    }
    .description {
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px !important;
      // cursor: pointer;
      img {
        width: 200px;
      }

      p {
        font-size: 16px;
        line-height: 26px;
        font-family: "Axiforma";
        font-weight: 500;
        font-style: normal;
        color: #666666;
        text-align: start;
      }
      p.Mui-expanded {
        color: white;
      }
      .custom_content {
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  .selected-product-details_right-content .Quantity h5 {
    // padding-left: 70px;
  }
}
@media only screen and (max-width: 992px) {
  .selected-product-details_right-content {
    text-align: center;
    margin-left: 0px;
    .Quantity {
      display: block;
      padding: 5px 15px 5px 8px !important;
      .arrows-component {
        right: 50%;
      }
      .arrow-bottom {
        left: 120px;
        top: -55px;
      }
      .arrow-top {
        right: -60px;
        top: -55px;
      }
      h5 {
        padding-left: 0;
        text-align: center;
      }
    }
    h1,
    h5 {
      text-align: center;
      padding-left: 0;
    }
  }
  .selected-product-details_right-content .Quantity .quantity {
    position: relative;
    margin: 15px auto;
  }
  .card-product-details-description_contact-right {
    .container {
      p {
        text-align: center;
        padding-right: 0;
        padding-top: 30px;
      }
    }
    .home_address::after {
      right: 50% !important;
      margin-right: -11px;
      top: 0 !important;
    }
    .telephone::after {
      right: 50% !important;
      margin-right: -9px !important;
      top: 0 !important;
    }
    .email::after {
      right: 50% !important;
      margin-right: -11px;
      top: 0 !important;
    }
  }
}

.jss3 {
  min-height: 56px;
  margin-bottom: -1px;
  background-color: transparent;
}

.MuiPaper-root .jss5 {
  padding: 0px 20px 0px 8px !important;
}

.custom_desc {
  display: flex;
  gap: 20px;
  // position: relative;
  // align-items: center;
  background: #fff;
  margin-bottom: 10px;
  padding: 10px;
  .custom_desc_span {
    font-family: "Axiforma";
    font-weight: 500;
    font-style: normal;
    color: #6d6d6d;
    font-size: 16px;
    border-right: 1px solid rgb(119, 119, 119);
    // margin-right: 15px;
    min-width: 150px;
  }
  .custom_desc_p {
    font-family: "Axiforma";
    font-weight: 500;
    font-style: normal;
    padding: 0;
    margin: 0 auto;
    color: #6d6d6d !important;
    width: 100%;
    position: relative;
  }

  .custom_desc_href {
    // font-family: "Axiforma";
    font-weight: 500;
    font-style: normal;
    padding: 0;
    color: #0bb3a2 !important;
    width: 100%;
    background: none;
    border: none;
    text-decoration: underline;
    text-align: left;
  }
  .custom_desc_href_citation {
    // margin-left: -40% !important;
    position: absolute;
    left: 33%;
  }
}
