.filters_for_products {
  padding-bottom: 20px;
  label.MuiFormControlLabel-root > span:nth-child(odd) {
    width: 40px !important;
  }
  .flexbox .MuiAccordion-root.Mui-expanded {
    margin-bottom: 0px;
  }
}

.flexbox {
  // .MuiAccordion-root.Mui-expanded {
  //   border-bottom-left-radius: none !important;
  //   border-bottom-right-radius: none !important;
  //   border-top-left-radius: none !important;
  //   border-top-right-radius: none !important;
  // }

  .filter {
    width: 250px;
    height: 20px;
    padding: 0px 15px;
    .filter__Header {
      color: #ffffff;
      background-color: #0bb3a2;
      font-weight: 600;
      border: solid 1px #dadada;
      border-radius: 2px;
      padding: 0px 0 0 20px;
      text-align: left;
    }
  }
}

.MuiAccordion-rounded {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: auto !important;
}
.MuiTypography-body1 {
  position: relative !important;
  cursor: pointer !important;
  font-size: 16px !important;
  line-height: 23px !important;
  color: #a5a5a5 !important;
  font-family: "Axiforma";
  font-weight: 500 !important;
  font-style: normal;
  margin-top: 1px;
}
.filters {
  color: #5c5c5c;
  text-align: start;
  box-sizing: border-box;
  .MuiFormControlLabel-root {
    display: flex;
    margin-bottom: 5px;
    align-items: self-start;
    .PrivateSwitchBase-root-1 {
      padding: 0 9px 9px 9px;
    }
  }
  .MuiIconButton-label {
    margin-top: -5px;
    color: #a5a5a5;
    justify-content: center;
  }
}
.filter_Title {
  font-size: 16px !important;
  line-height: 26px !important;
  color: #666666 !important;
  font-family: "Axiforma";
  font-weight: bold !important;
  font-style: normal;
  span {
    color: #0bb3a2;
    font-family: "Axiforma";
    font-weight: bold !important;
    font-style: normal;
    font-size: 15px;
  }
}
.MuiAccordion-rounded:last-child {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.MuiAccordion-rounded:first-child {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .flexbox {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .filter {
      justify-content: center;
      align-items: center;
      margin-top: 20%;
      margin-bottom: 10%;
      height: 100%;
      width: 100%;
      margin-left: -40px;
    }
  }
  // label.MuiFormControlLabel-root > span:nth-child(odd) {
  //   width: 40px !important;
  // }
}

.MuiAccordion-root {
  &::before {
    content: none !important;
    top: 0px !important;
    background-color: transparent !important;
  }
}

.filter_header_title {
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  font-family: "Axiforma";
  font-weight: 500;
  font-style: normal;
  background: #0bb3a2;
  padding: 13px 16px 11px 16px;
  margin: 0;
  text-align: left;
}
.MuiIconButton-colorSecondary:hover {
  background-color: none !important;
}
.MuiIconButton-colorSecondary:hover {
  background-color: none !important;
  background: none !important;
}
.MuiIconButton-colorSecondary {
  color: transparent !important;
}
.filters .MuiFormControlLabel-root .PrivateSwitchBase-root-1:hover {
  padding: 0 9px 9px 9px;
  background-color: none !important;
}

.accordian_search {
  margin: 0px 0 10px 0;
  height: 35px;
  text-align: center;
  .filter_search {
    border: 2px solid #0bb3a2;
    border-radius: 10px;
    width: 90%;
    height: 100%;
    padding: 5px;
    color: #999694;
    font-family: "Axiforma";
    font-weight: 300;
    font-style: normal;
    font-weight: 600;
    &::placeholder {
      color: #999694;
      font-family: "Axiforma";
      font-weight: 300;
      font-style: normal;
    }
  }

  .search-icon {
    position: absolute;
    max-width: 60px;
    max-height: 50px;
    width: 38px;
    height: 35px;
    right: 10px;
    background: #0bb3a2;
    color: #fff;
    border: 0px solid #0bb3a2;
    vertical-align: middle;
    text-align: center;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
    .MuiSvgIcon-root {
      color: #fff;
    }
  }
}

.MuiAccordionDetails-root {
  max-height: 350px !important;
  overflow-y: scroll;
}

.MuiAccordionDetails-root::-webkit-scrollbar {
  width: 6px !important;
}

.MuiAccordionDetails-root::-webkit-scrollbar-thumb {
  background-color: #0bb3a2 !important;
  border-radius: 10px !important;
  outline: 0 !important;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
  margin-right: -17px !important;
}
.accordion_custom {
  .MuiAccordionDetails-root {
    display: flex;
    padding: 8px 16px 0px;
  }
}
