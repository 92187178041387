@mixin for-phone-only-extra {
    @media (max-width: 600px) {
      @content;
    }
  }
  
  @mixin for-phone-only {
    @media (max-width: 767px) {
      @content;
    }
  }
  
  @mixin for-tablet-portrait {
    @media (max-width: 880px) {
      @content;
    }
  }
  
  @mixin for-tablet-landscape {
    @media (max-width: 1024px) {
      @content;
    }
  }
  
  @mixin for-laptop-s {
    @media (max-width: 1300px) {
      @content;
    }
  }
  
  @mixin for-laptop-min {
    @media (max-width: 1550px) {
      @content;
    }
  }
  
  @mixin for-big-laptop {
    @media (max-width: 1799px) {
      @content;
    }
  }
  
  @mixin for-big-desktop {
    @media (min-width: 2000px) {
      @content;
    }
  }
  
  
  
  // ===========================================================
  // Hovering icon animated
  // ===========================================================
  @mixin animated-hover($keyframe, $duration: 0.5s, $count: 3) {
    &:hover {
      .animated-hover {
        animation: $keyframe $duration;
        animation-iteration-count: $count;
      }
    }
  }
  @mixin truncate-oneline() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  @mixin respond($breakpoint) {
    @if $breakpoint == smallphone {
      // < 450px
      @media (max-width: 28.12em) {
        @content;
      }
    }
  
    @if $breakpoint == phone {
      // < 600px
      @media (max-width: 37.5em) {
        @content;
      }
    }
  
    @if $breakpoint == tab-port {
      @media (max-width: 56.25em) {
        // 900px
        @content;
      }
    }
    @if $breakpoint == tab-land {
      @media (max-width: 75em) {
        // 1200px
        @content;
      }
    }
    @if $breakpoint == normal-desktop {
      @media (max-width: 112.5em) and (min-width: 87.5em) {
        // 1400px
        @content;
      }
    }
    @if $breakpoint == big-desktop {
      @media (min-width: 112.5em) {
        // 1800px
        @content;
      }
    }
  }
  @mixin on-event-button($self: false) {
    @if $self {
      &,
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus {
        @content;
      }
    } @else {
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus {
        @content;
      }
    }
  }
  @mixin on-event($self: false) {
    @if $self {
      &,
      &:hover,
      &:active,
      &:focus {
        @content;
      }
    } @else {
      &:hover,
      &:active,
      &:focus {
        @content;
      }
    }
  }
  
  
  
  
  
  // ======================================================
  // Abstracts :: Mixins
  // ======================================================
  
  // Responsive breakpoint down (PC -> SP)
  // ======================================================
  // Example:
  //
  // .block {
  //   background-color: green;
  //
  //   @include breakpoint-down(desktop) {
  //     background-color: red;
  //   }
  // }
  $breakpoints: (
    'largeDesktop': (
      max-width: 1500px,
    ),
    'desktop': (
      max-width: 1199.98px,
    ),
    'tablet': (
      max-width: 991.98px,
    ),
    'phone': (
      max-width: 767.98px,
    ),
    'smallphone': (
      max-width: 575.98px,
    ),
  );
  
  @mixin breakpoint-down($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);
  
    @if $raw-query {
      $query: if(type-of($raw-query) == 'string', unquote($raw-query), inspect($raw-query));
  
      @media #{$query} {
        @content;
      }
    } @else {
      @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
    }
  }
  
  // Position
  // ======================================================
  // Example:
  //
  // .class {
  //   @include position(absolute, 10px, 10px, 10px, 10px);
  // }
  @mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }
  
  // Width !== Height
  // ======================================================
  // Example:
  //
  // .class {
  //   @include size(10px, 20px);
  // }
  @mixin size($width, $height) {
    width: $width;
    height: $height;
  }
  
  // Width === Height
  // ======================================================
  // Example:
  //
  // .class {
  //   @include square(10px);
  // }
  @mixin square($size) {
    @include size($size, $size);
  }
  
  // Width === Height + Border Radius
  // ======================================================
  // Example:
  //
  // .class {
  //   @include circle(10px);
  // }
  @mixin circle($size) {
    @include size($size, $size);
    border-radius: 50%;
  }
  
  // Reset UL OL
  // ======================================================
  // Example:
  //
  // .class {
  //   @include list-unstyled();
  // }
  @mixin list-unstyled() {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  // Truncate oneline
  // ======================================================
  // Already supported in Bootstrap 4 mixin: @include text-truncate()
  // Example:
  //
  // .class {
  //   @include truncate-oneline();
  // }
  @mixin truncate-oneline() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  // Truncate multiline with CSS3
  // ======================================================
  // Example:
  //
  // .class {
  //   @include truncate-multiline-new(3);
  // }
  @mixin truncate-multiline-new($line-number) {
    overflow: hidden;
    text-overflow: ellipsis;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-line-clamp: $line-number;
  
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
  
  // Truncate multiline with CSS2
  // Example:
  //
  // .class {
  //   @include truncate-multiline-old({ $line-number: 2 });
  // }
  @mixin truncate-multiline-old($line-height: 1.2em, $line-number: 1, $bg-color: $white) {
    overflow: hidden;
    position: relative;
    line-height: $line-height;
    max-height: $line-height * $line-number;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
  
    &:before {
      content: '...';
      position: absolute;
      right: 0;
      bottom: 0;
    }
  
    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: .2em;
      background: $bg-color;
    }
  }
  
  // ===========================================================
  // Mixin for BEM - Elements
  // ===========================================================
  @mixin e($selector) {
    &__#{$selector} {
      @content;
    }
  }
  
  // ===========================================================
  // Mixin for BEM - Modifier
  // ===========================================================
  @mixin m($selector) {
    &--#{$selector} {
      @content;
    }
  }
  
  // ===========================================================
  // Hovering icon animated
  // ===========================================================
  @mixin animated-hover($keyframe, $duration: .5s, $count: 3) {
    &:hover {
      .animated-hover {
        animation: $keyframe $duration;
        animation-iteration-count: $count;
      }
    }
  }
  
  // ===========================================================
  // Transition
  // ===========================================================
  @mixin transition($property: all, $duration: .3s, $easing: ease) {
    transition: $property $duration $easing;
  }
  
  // ===========================================================
  // Image Background Responsive
  // ===========================================================
  @mixin background-image-responsive() {
    max-width: 100%;
    height: 0;
    // padding-bottom: ($height/$width) * 100%;
  }
  
  // ===========================================================
  // Image Background Responsive
  // ===========================================================
  @mixin bg-img-responsive($height, $width) {
    max-width: 100%;
    height: 0;
    padding-bottom: ($height/$width) * 100%;
  }
  
  // ===========================================================
  // Max Width Centering
  // ===========================================================
  @mixin width-centering($width) {
    width: $width;
    max-width: 100%;
    margin: 0 auto;
  }
  
  // ===========================================================
  // Width
  // ===========================================================
  @mixin width($width) {
    width: $width;
    max-width: 100%;
  }
  
  /// Event wrapper
  /// @author Harry Roberts
  /// @param {Bool} $self (false) - Whether or not to include current selector
  /// @see https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
  @mixin on-event($self: false) {
    @if $self {
      &,
      &:hover,
      &:active,
      &:focus {
        @content;
      }
    } @else {
      &:hover,
      &:active,
      &:focus {
        @content;
      }
    }
  }
  
  /// Event wrapper
  /// @author Ha Huu Tin
  /// @param {Bool} $self (false) - Whether or not to include current selector
  @mixin on-event-button($self: false) {
    @if $self {
      &,
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus {
        @content;
      }
    } @else {
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus {
        @content;
      }
    }
  }
  
  // Remove/ignore `:hover` css style on touch devices
  @mixin touch-device() {
    @media (hover: none) {
      @content;
    }
  }
  
  // Hover icon SVG
  @mixin icon-hovering() {
    color: var(--color-icon);
    transition: .3s;
    cursor: pointer;
  
    &:hover {
      fill: var(--color-icon-hover) !important;
    }
  }