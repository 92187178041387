.loading_state {
  color: #0bb3a2;
  font-size: 22px;
  font-family: "Axiforma";
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

.no_products_found {
  // position: absolute;
  width: 100%;
  // text-align: center;
  // display: block;
  // height: auto;
  // left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  #no_products_msg {
    color: #0bb3a2;
    font-size: 18px;
    width: 70%;
    font-family: "Axiforma";
    font-weight: bold;
    font-style: normal;
    text-align: center;
    margin: 0 auto;
    // padding-top: 50px;
    padding-bottom: 50px;
  }
  #no_products {
    color: #0bb3a2;
    font-size: 18px;
    width: 70%;
    font-family: "Axiforma";
    font-weight: bold;
    font-style: normal;
    text-align: center;
    margin: 0 auto;
    padding-top: 50px;
  }
  .btns_no_products {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 50px;
  }
}

.lol {
  min-height: 60vh !important;
}
