.checkout_table {
  border: 1px solid #dadada;
  padding: 0 0px;
  border-bottom: none;
  margin-bottom: 20px;
  .checkout_head {
    display: flex;
    position: relative;
    border-bottom: 1px solid #dadada;
    padding: 20px 0 20px 0;
    align-items: center;
  }
  .header-block {
    text-align: left !important;
    padding: 0 20px;
    span {
      color: #909090;
      font-size: 20px;
      line-height: 30px;
      font-family: "Axiforma";
      font-weight: 500;
      font-style: normal;
      text-transform: capitalize;
    }
  }
  .header-block1 {
    text-align: center;
    padding: 0 20px;
    span {
      color: #909090;
      font-size: 20px;
      line-height: 30px;
      font-family: "Axiforma";
      font-weight: 500;
      font-style: normal;
      text-transform: capitalize;
    }
  }
  .name {
    font-size: 20px;
    line-height: 30px;
    color: #0bb3a2;
    font-family: "Axiforma";
    font-weight: bold;
    font-style: normal;
    text-transform: capitalize;
  }
  .x_style {
    text-align: center;
  }
  .x_style span {
    font-size: 26px;
    color: #676767;
  }
  .border_h {
    padding: 20px 0px;
    border-top: 1px solid#dadada;
    border-bottom: 1px solid#dadada;
  }

  .total {
    font-size: 26px;
    line-height: 36px;
    text-align: right;
    color: #676767;
  }

  .total span {
    color: #0bb3a2;
  }
}
@media only screen and (max-width: 767px) {
  .checkout_table .header-block {
    text-align: center !important;
    padding: 0;
  }
}

.redirect_to_quote {
  display: inline-block;
  font-size: 21px;
  line-height: 31px;
  cursor: pointer;
  color: #fff;
  font-family: "Axiforma";
  font-weight: bold;
  font-style: normal;
  border: 1px solid #11b7a6;
  background-color: #11b7a6;
  border-radius: 5px;
  padding: 7px 34px;
  margin: 0 30px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    border: 1px solid #0bb3a2;
    background-color: #ffffff;
    color: #0bb3a2;
  }
}

.empty-message {
  display: block;
  font-size: 18px;
  font-family: "Axiforma";
  font-weight: 500;
  font-style: normal;
  color: #c2c2c2;
}

.find_products {
  font-size: 21px;
  width: 30%;
  color: #fff;
  font-family: "Axiforma";
  font-weight: bold;
  font-style: normal;
  border: 1px solid #11b7a6;
  background-color: #11b7a6;
  border-radius: 5px;
  padding: 7px 30px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    background-color: #fff;
    border: 1px solid #0bb3a2;
    color: #0bb3a2;
  }
}
