@import "../../utils/mixins";

.search_bar_products {
  margin: 0 auto;
  background-color: #fff;
  border-radius: 15px;
  position: relative;
  left: 0;
  justify-content: space-between;
  margin-bottom: 30px;
  .inputsearch {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 50px;
    width: 100%;
    border: 2px solid #0bb3a2;
    border-radius: 15px;
    padding: 8px;
    .filters {
      background-color: transparent;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      border: none;
      outline: none;
      margin-right: 15px;
      color: #999694;
      ::-ms-expand {
        display: none;
      }
    }
    .search-button {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      border-left-width: 2px;
      width: 100%;
      .search-icon {
        position: relative;
        max-width: 60px;
        max-height: 50px;
        width: 60px;
        height: 50px;
        right: -10px;
        top: 0;
        background: #0bb3a2;
        color: #fff;
        border: 0px solid #0bb3a2;
        vertical-align: middle;
        text-align: center;
        border-top-right-radius: 13px;
        border-bottom-right-radius: 13px;
        .MuiSvgIcon-root {
          color: #fff;
        }
      }
      input[type="text"] {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 10px;
        width: 100%;
        outline: none;
        border: none;
        background-color: transparent;
        color: #999694;
        font-family: "Axiforma";
        font-weight: 300;
        font-style: normal;
      }
      ::placeholder {
        color: #999694;
        @include for-phone-only {
          font-size: 15px;
        }
      }
      .MuiSvgIcon-root {
        color: #999694;
      }
      .no-autocomplete {
        color: #0bb3a2;
        width: 35%;
        @include for-big-laptop {
          width: 60%;
        }
        @include for-laptop-min {
          width: 70%;
        }
        @include for-laptop-s {
          width: 80%;
        }
        @include for-tablet-portrait {
          width: 90%;
        }
      }
      .autocomplete {
        list-style: none;
        align-items: center;
        width: 100%;
        height: auto;
        background-color: #fff;
        left: 0;
        top: 50px;
        margin-left: 0;
        position: absolute;
        // border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
        // border-bottom-left-radius: 15px;
        // border-bottom-right-radius: 15px;
        // border: 2px solid #0bb3a2;
        border-top: none;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding: 8px;
        // max-height: 220px;
        // height: 220px;
        // overflow: hidden;
        // overflow-y: scroll;
        z-index: 99;
        // @include for-big-laptop {
        //   width: 555px;
        // }
        // @include for-laptop-min {
        //   width: 417px;
        // }
        // @include for-tablet-portrait {
        //   width: 185px;
        // }
        #no_search_result {
          text-align: center;
          margin-top: 10px;
          font-family: "Axiforma";
          font-weight: 500;
          font-style: normal;
          color: #0bb3a2;
        }
        #no_result_found {
          font-family: "Axiforma";
          font-weight: 300;
          font-style: normal;
          color: #ccc;
          padding: 10px 0;
        }
        .suggestion_title {
          color: #0bb3a2;
          font-family: "Axiforma";
          font-weight: 500;
          font-style: normal;
          font-size: 17px;
          margin-top: 15px;
          margin-bottom: 2px;
          position: relative;
          display: block;
          margin-bottom: 5px;
          &:after {
            position: absolute;
            content: "";
            width: 4%;
            height: 2px;
            left: 0;
            bottom: 0;
            background-color: #0bb3a2;
          }
        }
        .search_size {
          color: #0bb3a2;
          font-family: "Axiforma";
          font-weight: 300;
          font-style: normal;
        }
      }
      .autocomplete li {
        list-style: none;
        padding: 8px;
        font-family: "Axiforma";
        font-weight: 300;
        font-style: normal;
        font-size: 13px;
      }
      .autocomplete > .active,
      .autocomplete li:hover {
        background-color: #eee;
        color: #0bb3a2;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 700;
      }
      // .autocomplete li:not(:last-of-type) {
      //   border-bottom: 1px solid #eee;
      // }
    }
  }
  ul::-webkit-scrollbar {
    width: 2px;
    cursor: pointer !important;
  }
  ul::-webkit-scrollbar-thumb {
    background-color: #0bb3a2;
    outline: 1px solid #ccc;
    cursor: pointer !important;
  }
  ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgb(0 0 0 / 45%);
    cursor: pointer !important;
  }
}
*:focus {
  outline: none;
}
