.container__selected__categories {
  display: block;
  strong {
    color: #0bb3a2;
    font-weight: 700;
  }
  .refine {
    font-weight: 600;
    color: #0bb3a2;
    font-size: 15px;
    padding: 0;
    text-align: left;
    font-family: "Axiforma";
    font-weight: 300;
    font-style: normal;
  }
  #keyword {
    padding: 0 !important;
  }

  p {
    font-weight: 600;
    color: #000000;
    font-size: 20px;
    // padding: 20px 0 0;
    text-align: left;
    font-family: "Axiforma";
    font-weight: 300;
    font-style: normal;
  }
  .selected__categories {
    margin-top: 10px;
    border-bottom: 0.2px solid gainsboro;
    border-top: 0.2px solid gainsboro;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    column-gap: 10px;
    row-gap: 10px;
    align-items: baseline !important;
    .selected__card {
      width: auto;
      height: auto;
      padding: 8px 15px 10px 15px;
      border-radius: 50px;
      display: inline-block;
      justify-content: center;
      align-items: center;
      color: #fff;
      border: 2px solid #0bb3a2;
      background: #0bb3a2;
      cursor: pointer;
      .MuiSvgIcon-root {
        margin-top: 0px !important;
        background: #fff;
        color: #0bb3a2;
        border-radius: 50%;
      }

      p {
        color: #fff !important;
      }
      .seleceted_name {
        font-weight: 600;
        font-size: 13px;
        line-height: 23px;
        text-decoration: none;
        background-color: transparent;
        display: inline-block;
        margin-bottom: 0;
        padding: 0;
        margin: 0 0 10px 0 !important;
      }
      span {
        cursor: pointer;
        border-radius: 50%;
        display: inline-block;
      }
    }
    .selected__card__clear__all {
      width: 150px;
      height: auto;
      padding: 10px;
      border-radius: 50px;
      display: inline-block;
      align-items: center;
      background-color: #0bb3a2;
      border: 1px solid #0bb3a2;
      cursor: pointer;
      p {
        color: #fff;
        font-weight: 600;
        font-size: 15px;
        line-height: 25px;
        margin-bottom: 0;
        text-decoration: none;
        background-color: transparent;
        text-align: center;
        padding: 0;
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  //  .container__selected__categories{
  //      width: 100%;
  //     .selected__categories{
  //         flex-direction: column;
  //         justify-content: center;
  //         align-items: center;
  //     }
  //  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.reload_prod {
  display: inline-block;
  font-size: 21px;
  line-height: 31px;
  color: #fff;
  font-family: "Axiforma";
  font-weight: bold;
  font-style: normal;
  border: 1px solid #11b7a6;
  background-color: #11b7a6;
  border-radius: 5px;
  padding: 7px 30px;
  margin: 0 30px 0 0;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #ffffff;
    color: #0bb3a2;
    border: 1px solid #0bb3a2;
  }
}
