.product_table {
  border-bottom: 1px solid #dadada;
  padding: 10px 20px;
  align-items: center;

  .image-container {
    padding-right: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .text_name {
    text-align: left;
    .name {
      cursor: pointer;
    }
  }

  .quantity {
    display: flex;

    .arrow {
      cursor: pointer;
    }

    .value {
      margin: 0 10px;
    }
  }

  .remove-button {
    padding-left: 12px;
    cursor: pointer;
  }
}
.arrows-component-next {
  text-align: center;
  .arrows-next {
    display: flex;
    border: 1px solid #ccc;
    width: 120px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    position: relative;
    margin: 0 auto;
    float: right;
    margin-right: 20px;
    .arrow-top-next {
      display: inline-flex;
      border: none;
      background: none;
      color: #ccc;
      padding-left: 25px;
    }
    .arrow-bottom-next {
      display: inline-flex;
      border: none;
      background: none;
      color: #ccc;
      padding-right: 25px;
    }
    .numbering-next {
      position: absolute;
      left: 38%;
      width: 30px;
      height: auto;
      top: 7px;
      color: #686868;
    }
  }
}
@media only screen and (max-width: 992px) {
  .checkout_table .header-block1 {
    text-align: left;
    padding: 0 20px;
    padding-left: 65px !important;
  }
}
@media only screen and (max-width: 767px) {
  .checkout_table .header-block {
    text-align: center;
    padding: 0;
  }
  .product_table .image-container {
    padding-right: 0;
    padding: 20px 0;
  }
  .product_table .text_name {
    text-align: center !important;
    padding-bottom: 20px;
    .name {
      cursor: pointer;
    }
  }
  .arrows-component-next .arrows-next {
    display: inline-block;
    border: 1px solid #ccc;
    width: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    position: relative;
    margin: 11px auto;
    float: none;
    margin-right: -15px;
  }
  .product_table .remove-button {
    padding-left: 0;
    cursor: pointer;
  }
  .checkout_table .header-block1 {
    text-align: center;
    padding: 0 20px;
    padding-left: 0 !important;
  }
}
