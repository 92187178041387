@import "../../utils/mixins";

.rowSuppliers {
  justify-content: center;
  margin-top: 20px;
  .flexed {
    width: 500px !important;
  }

  .supplier_filters {
    background-color: #fff;
    padding: 20px;
    width: 100%;
    border-radius: 5px;
    margin-top: 30px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    .css-2b097c-container {
      width: 50%;
      @include for-tablet-portrait {
        width: 100%;
      }
    }
    .suppliers-button {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      border-left-width: 2px;
      width: 100%;
      .search-icon {
        position: relative;
        max-width: 60px;
        max-height: 40px;
        width: 60px;
        height: 50px;
        right: 12px;
        top: 0;
        background: #0bb3a2;
        color: #fff;
        border: 0px solid #0bb3a2;
        vertical-align: middle;
        text-align: center;
        border-top-right-radius: 13px;
        border-bottom-right-radius: 13px;
        .MuiSvgIcon-root {
          color: #fff;
        }
      }
    }
    @include for-tablet-portrait {
      flex-direction: column;
    }
  }

  .supplier {
    display: flex;
    flex-direction: column;
    padding: 50px;
    padding: 65px;
    width: 100%;
    text-align: center;
    align-items: center;

    h1 {
      justify-content: center;
      text-align: center;
      width: 60%;
      color: #0bb3a2;
      font-size: 60px;
      line-height: 70px;
      font-weight: bold;
      font-family: "Axiforma";
      font-weight: bold;
      font-style: normal;
    }

    h5 {
      padding-top: 35px;
      width: 44%;
      color: #666666;
      font-family: "Axiforma";
      font-weight: bold;
      font-style: normal;
    }

    p {
      padding-top: 30px;
      width: 60%;
      font-family: "Axiforma";
      font-weight: 500;
      font-style: normal;
      color: #666666;
    }

    #hr {
      width: 55px;
      height: 3px;
      opacity: 1;
      color: #0bb3a2;
    }
  }
  #inputKey {
    padding: 15px;
    border: 2px solid #dadada;
    border-radius: 15px;
    width: 100%;
    height: 40px;
    display: inline-block;
    color: #777;
    font-family: "Axiforma";
    font-weight: 300;
    font-style: normal;
  }

  #inputKey::placeholder {
    color: #b2b2b2;
  }

  #hr2 {
    margin-top: 40px;
    color: #6b6969;
    margin-bottom: 50px;
    width: 100%;
  }

  .suppliers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    width: 70%;
    // gap: 10px;
    @include for-laptop-s {
      gap: 10px;
    }
    @include for-tablet-portrait {
      width: 100%;
    }
    .suppliers_content {
      flex-basis: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      // a {
      //   text-decoration: none;
      //   display: inline-block;
      //   color: #d1d1d1;
      // }

      p {
        position: relative;
        display: block;
        font-family: "Axiforma";
        font-weight: 300;
        font-style: normal;
        padding-top: 12px;
        cursor: pointer;
        width: 150px;
        text-align: center;
        margin: 0 auto;
        font-size: 16px;
      }
      p:after {
        position: absolute;
        content: "";
        width: 130px;
        height: 1px;
        top: 0;
        left: 50%;
        margin-left: -65px;
        background-color: #c2bdbd;
      }
      .square {
        border: 2px solid #c2bdbd;
        display: inline-flex;
        padding: 5px;
        margin: 0 auto 10px auto;
        background-color: #fff;
        width: 150px;
        height: 105px;
        border-radius: 15px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .supp_imgs {
          max-width: 100%;
          display: block;
          border-radius: 15px;
          // height: 100% !important;
        }
        .no_img_supp {
          max-width: 100%;
          display: block;
          border-radius: 15px;
          height: 100% !important;
        }
      }

      #hr3 {
        width: 130px;
        margin-left: 15px;
      }
    }
  }
}

.MuiPaginationItem-outlinedSecondary.Mui-selected {
  background-color: #0bb3a2;
  color: #fff;
}
.MuiFormControl-root {
  width: 70%;
  left: 12px;
  display: flex;
}
.MuiPagination-root {
  text-align: center;
  .MuiPaginationItem-root {
    margin: 0;
    border-radius: 0;
    background: #fff;
    border: 1px solid #ccc;
    min-height: 34px;
    height: 34px;
    :first-child {
      color: #0bb3a2;
    }
  }
  .MuiPaginationItem-page.Mui-selected {
    background: #0bb3a2 !important;
    color: #fff !important;
    border: 1px solid #0bb3a2 !important;
  }
}
.MuiPagination-ul {
  display: inline-flex;
  padding: 0 0 0 0;
  margin-top: 5px;
  margin-right: 12px;
  justify-content: center;
}
.MuiPaginationItem-rounded {
  border-radius: inherit;
}

.MuiPaginationItem-outlinedSecondary.Mui-selected {
  border: 1px solid #0bb3a2;
}
.MuiPaginationItem-outlinedSecondary.Mui-selected:hover,
.MuiPaginationItem-outlinedSecondary.Mui-selected.Mui-focusVisible {
  background-color: #0bb3a2;
}

.supPagination {
  position: relative;
  text-align: center;
  padding-bottom: 100px;

  .MuiPaginationItem-page.Mui-selected {
    background-color: #fff;
    color: #0bb3a2;
    border-color: #ccc;
  }
  .MuiPaginationItem-page {
    font-size: 17px;
  }
  .MuiPaginationItem-page.Mui-selected:hover,
  .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .MuiPaginationItem-root {
    color: #b2b2b2;
    margin: 0;
    min-height: 36px;
    min-width: 36px;
  }
}

.css-xb97g8:hover {
  background-color: #0bb3a2 !important;
  color: #fff !important;
}

.css-1pahdxg-control:hover {
  border-color: #0bb3a2 !important;
}

.css-1pahdxg-control {
  border: 1px solid #0bb3a2 !important;
  box-shadow: 0 0 0 1px #0bb3a2 !important;
}

.css-1rhbuit-multiValue {
  background-color: #eee !important;
}

.css-1wa3eu0-placeholder {
  font-family: "Axiforma" !important;
  font-weight: 300 !important;
  font-style: normal;
}

.css-12jo7m5 {
  font-family: "Axiforma" !important;
  font-weight: 300 !important;
  font-style: normal;
}
