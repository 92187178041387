@import "../../utils/mixins";

.card_product_items {
  padding: 30px 30px 30px 30px;
  border: 1px solid #dadada;
  margin: 0 0 30px 0;
  background: white;

  text-align: left;
  .price {
    margin-top: 30px;
  }
  .MuiButton-containedSecondary {
    color: #fff;
    background-color: #0bb3a2;
    border-radius: 5px;
    border: 1px solid #0bb3a2;
    padding: 18px !important;
    font-size: 17px;
    line-height: 23px;
    font-family: "Axiforma";
    font-weight: 500 !important;
    font-style: normal;
    text-transform: capitalize;
    margin-left: 15px;
  }
  .MuiButton-containedSecondary:hover {
    background-color: #f3f3f3;
    color: #0bb3a2;
    border: 2px solid #0bb3a2;
  }
  h1 {
    font-size: 46px;
    line-height: 56px;
    color: #4a4a4a;
    font-family: "Axiforma";
    font-weight: bold;
    font-style: normal;
    text-align: right;
  }
  .img_responsive {
    img {
      max-width: 100%;
      display: block;
      cursor: pointer;
      width: auto;
      // height: 200px;
      @include for-phone-only {
        margin: 0 auto;
      }
    }
  }
  .table-products-info {
    // margin: 0;
    // border-collapse: collapse;
    // border-spacing: 0;
    // margin-bottom: 20px;
    // width: 110%;
    td {
      color: #000;
      font-family: "Axiforma";
      font-weight: 300;
      font-style: normal;
      padding: 8px 10px 8px 15px;
      font-size: 14px;
      line-height: 18px;
      font-weight: normal;
      vertical-align: middle;
    }
  }
  a {
    text-decoration: none;
  }
  h2 {
    display: flex;
    color: #0bb3a2;
    font-size: 22px;
    line-height: 32px;
    font-family: "Axiforma";
    font-weight: bold;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    margin-bottom: 20px;
    display: inline-block;
    cursor: pointer;
  }
  .card__product__container {
    display: flex;
    .card__product__items__left {
      width: 20%;
      // height: 100%;
      display: flex;
      padding-left: 20px;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 180px;
        height: 180px;
        display: block;
      }
    }
    .card__product__items__right {
      display: flex;
      justify-content: flex-start;
      text-align: left;
      width: 35%;
      height: 100%;
      margin-left: 5%;
      margin-top: 10px;
      table > tbody > tr > td {
        color: #b8b8b8;
        padding: 10px 12px 10px 17px;
        line-height: 15px;
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  // .card_product_items{
  //   flex-direction: column;
  //   justify-content: start;
  //   align-items: start;
  //   height: 100%;
  //   width: 85%;
  //   margin-left: -40px;
  //   .card__product__container{
  //     height: 100%;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     .card__product__items__left{
  //       width: 70%;
  //       img{
  //         width:370px;
  //         height:350px;
  //         display: block;
  //         align-items: center;
  //         justify-content: center;
  //       }
  //     }
  //     .card__product__items__right{
  //       width: 120%;

  //       table>tbody>tr>td{
  //         color: #b8b8b8;
  //         padding: 10px 12px 10px 17px;
  //         line-height: 15px;
  //       }
  //     }
  //   }
  //  }
}
.jss5 {
  padding: 0px 5px;
}
.react-fullscreen-slideshow-bottom-left-pane {
  display: none !important;
}

.react-fullscreen-slideshow-gallery-icon-bar {
  display: none !important;
}
