.cart-dropdown {
  // position: fixed;
  // top: 100px;
  // right: 9%;
  // background: #fff;
  // box-shadow: 0px 0px 55px -30px #ccc;
  // height: auto;
  // max-height: 700px;
  // padding: 30px 15px 30px 20px;
  // z-index: 999999;
  // pointer-events: all;
  // transition: 0.3s;
  // overflow: hidden;
  // overflow-y: scroll;
  // width: 25%;
  // height: auto;
  .cart_header {
    display: flex;
    justify-content: space-between;
    width: 82.5%;
    h3 {
      font-size: 19px;
      font-family: "Axiforma";
      font-weight: 500;
      font-style: normal;
      padding-bottom: 10px;
      color: #0bb3a2;
    }
  }

  .go_to_cart {
    display: flex;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    justify-content: center;
    text-transform: uppercase;
    align-items: center;
    display: flex;
    background: #0bb3a2;
    color: #fff;
    border: 1px solid #0bb3a2;
    padding: 7px;
    // display: inline-block;
    margin: 0 auto;
    text-align: center;
    font-size: 15px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    max-width: 50%;
    border-radius: 5px;
    width: 45%;
    text-decoration: none;
  }
  .go_to_cart:hover {
    background-color: #fff;
    border: 1px solid #0bb3a2;
    color: #0bb3a2;
  }

  .cart-items {
    display: block;
    text-align: center;
    .empty-message {
      margin-bottom: 30px;
      display: block;
      font-size: 18px;
      line-height: 28px;
      font-family: "Axiforma";
      font-weight: 500;
      font-style: normal;
      color: #c2c2c2;
      text-align: left;
    }
    .find_products {
      font-size: 21px;
      width: 100%;
      color: #fff;
      font-family: "Axiforma";
      font-weight: bold;
      font-style: normal;
      border: 1px solid #11b7a6;
      background-color: #11b7a6;
      border-radius: 5px;
      padding: 7px 30px;
      transition: all 0.3s ease-in-out;
      text-decoration: none;
      &:hover {
        background-color: #fff;
        border: 1px solid #0bb3a2;
        color: #0bb3a2;
      }
    }
  }
  .cart_btns {
    display: flex;
  }
}
div::-webkit-scrollbar {
  width: 0.2em;
}
div::-webkit-scrollbar-thumb {
  background-color: #0bb3a2;
  outline: 1px solid slategrey;
}
