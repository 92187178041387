.Header {
  padding: 20px 0;
  .MuiPaginationItem-outlinedSecondary.Mui-selected {
    background-color: #0bb3a2;
    color: #fff;
  }
  .MuiFormControl-root {
    width: 70%;
    left: 12px;
    display: flex;
  }
  .MuiPagination-root {
    text-align: right;
    .MuiPaginationItem-root {
      border-radius: 0;
      background: #fff;
      border: 1px solid #ccc;
      min-height: 34px;
      height: 34px;
      :first-child {
        color: #0bb3a2;
      }
    }
    .MuiPaginationItem-page.Mui-selected {
      background: #0bb3a2;
      color: #fff;
      border: 1px solid #0bb3a2;
    }
  }
  .MuiPagination-ul {
    display: inline-flex;
    padding: 0 0 0 0;
    margin-top: 5px;
    margin-right: 12px;
  }
  .MuiPaginationItem-rounded {
    border-radius: inherit;
  }
  .MuiPaginationItem-root {
    margin: 0;
  }
  .MuiPaginationItem-outlinedSecondary.Mui-selected {
    border: 1px solid #0bb3a2;
  }
  .MuiPaginationItem-outlinedSecondary.Mui-selected:hover,
  .MuiPaginationItem-outlinedSecondary.Mui-selected.Mui-focusVisible {
    background-color: #0bb3a2;
  }
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  display: none;
}
.MuiFormLabel-root.Mui-focused {
  color: #fff;
}

.jump_page_section {
  display: flex;
  padding: 0 0 0 12px;
  align-items: center;
  .jump_to_page {
    border: 2px solid #0bb3a2;
    width: 100%;
    height: 100%;
    padding: 5px;
    color: #999694;
    font-weight: 600;
    &::placeholder {
      color: #999694;
      font-family: "Axiforma";
      font-weight: 300;
      font-style: normal;
    }
  }
  .jump {
    width: auto;
    background-color: #0bb3a2;
    border: transparent;
    padding: 5px;
    height: 33px;
    color: #fff;
    cursor: pointer;
  }
}

.ant-select {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .dblock {
    display: inline-block;
  }
  .Header .MuiPagination-root {
    text-align: center;
  }
}

.ant-pagination-item {
  margin-right: 0px !important;
  border-radius: 0px !important;
}
.ant-pagination-item-active {
  background: #0bb3a2 !important;
  color: #fff !important;
  border: 1px solid #0bb3a2 !important;
}

.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: #0bb3a2 !important;
  transition: all 0.3s !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 0px !important;
}

.text-right {
  text-align: right;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  align-items: center;
  justify-content: center;
  display: flex !important;
}

.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 0px !important;
}
.ant-pagination-options-quick-jumper input {
  border-radius: 0px !important;
  font-family: "Axiforma";
  font-weight: 300;
  font-style: normal;
  &:focus {
    border-color: #0bb3a2 !important;
    box-shadow: none !important;
  }
  &:hover {
    border-color: #0bb3a2 !important;
  }
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #0bb3a2 !important;
  margin-top: -5px !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
  background: #ffffff !important;
  color: #0bb3a2 !important;
  border: 1px solid #0bb3a2 !important;
}
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root:hover {
  background: #ffffff !important;
  color: #0bb3a2 !important;
  border: 1px solid #0bb3a2 !important;
}

.paginationBttns {
  display: flex;
  width: 100%;
  justify-content: center;
  li {
    list-style: none;
  }
  svg {
    font-size: 18px;
    margin-bottom: -4px;
    color: #0bb3a2;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    padding: 5px 0 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    line-height: 1;
    font-size: 15px;
    font-family: "Axiforma";
    font-weight: 300;
    font-style: normal;
    &:hover {
      border: 1px solid #0bb3a2;
    }
  }
  .paginationActive a {
    background-color: #0bb3a2;
    color: white;
    border: 1px solid #0bb3a2;
    &:hover {
      color: white;
    }
  }
}
