.selected-product {
  margin-top: 5%;
  justify-content: center;
  align-items: end;
  .continue_shopping {
    display: inline-block;
    font-size: 21px;
    line-height: 31px;
    color: #fff;
    font-family: "Axiforma";
    font-weight: bold;
    font-style: normal;
    border: 1px solid #11b7a6;
    background-color: #11b7a6;
    border-radius: 5px;
    padding: 7px 30px;
    margin: 0 30px 0 0;
    cursor: pointer;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #ffffff;
      color: #0bb3a2;
      border: 1px solid #0bb3a2;
    }
    svg {
      font-size: 20px;
    }
  }
  &-details_left {
    #productName {
      margin-left: 0;
    }
    h2 {
      font-size: 30px;
      line-height: 40px;
      text-align: start;
      font-family: "Axiforma";
      font-weight: 500;
      font-style: normal;
      color: #686868;
      margin-left: 30%;
    }
    .img_padding {
      padding: 20px;
      border: 2px solid #ccc;
      p {
        margin-top: 30px;
        font-family: "Axiforma";
        font-weight: 500;
        font-style: normal;
        color: #484848;
      }
    }
    .product_images {
      width: 100%;
      height: auto;
    }
  }
  .MuiIconButton-root:hover {
    background: none;
  }
  .MuiIconButton-root {
    padding: 12px 0 12px 12px;
  }

  &-details_right-content {
    width: 100%;
    text-align: right;
    // margin-top: -40px;
    margin-top: 80px;
    h1 {
      font-size: 50px;
    }
    .Quantity {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      margin-top: 0;

      .minus_quantity {
        position: absolute;
        z-index: 2;
        right: 31%;
        background: none;
        border: none;
        font-size: 30px;
        cursor: pointer;
      }
      .add_quantity {
        position: absolute;
        right: 0%;
        background: none;
        border: none;
        font-size: 30px;
        cursor: pointer;
      }
      .btns {
        background-color: black;
        font-family: "Axiforma";
        font-weight: bold;
        font-style: normal;
        color: #c2c2c2;
      }
      h5 {
        font-size: 18px;
        line-height: 28px;
        font-family: "Axiforma";
        font-weight: 500;
        font-style: normal;
        // color: #c2c2c2;
        margin: 0;
        margin-right: 90px;
      }
      .arrows-component {
        position: absolute;
        width: 100%;
        display: block;
        width: 200px;
        height: 50px;
        right: 0;
      }
      .quantity {
        display: inline-block;
        width: 170px;
        height: 55px;
        font-size: 18px;
        position: absolute;
        right: 0;
        line-height: 28px;
        color: #a0a0a0;
        background: transparent;
        border: 2px solid #dedede;
        border-radius: inherit;
        text-align: center;
        font-weight: 500;
      }
      .arrow-top {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: transparent;
        right: 25px;
        border: none;
        color: #cccccc;
        top: 12px;
        cursor: pointer;
        cursor: hand;
      }
      .arrow-bottom {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: transparent;
        left: 35px;
        border: none;
        color: #cccccc;
        top: 12px;
        cursor: pointer;
        cursor: hand;
      }
      .MuiSvgIcon-root {
        color: #ccc;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
      .MuiSvgIcon-root:hover {
        color: #0bb3a2;
      }
    }
    .add_fav {
      background: #0bb3a2;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
      border: none;
      margin-top: 3px;
      margin-right: 10px;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      :hover {
        background-color: transparent;
        color: #0bb3a2;
      }
      svg {
        font-size: 42px;
      }
    }
    .MuiButton-containedSecondary {
      display: inline-block;
      font-size: 21px;
      line-height: 31px;
      color: #fff;
      font-family: "Axiforma";
      font-weight: bold;
      font-style: normal;
      border: 1px solid #11b7a6;
      background-color: #11b7a6;
      border-radius: 5px;
      padding: 7px 30px;
      margin-top: 35px;
      text-decoration: none;
      text-transform: none;

      .add_cart_icon_start {
        margin-top: -10px;
      }
    }
    .MuiButton-containedSecondary:hover {
      background-color: #ffffff;
      color: #0bb3a2;
      border: 1px solid #0bb3a2;
    }

    .MuiSvgIcon-colorPrimary {
      color: #ffffff;
      background-color: #0bb3a2;
      border-radius: 5px;
      border: 1px solid #0cd3bf;
    }
    h1 {
      font-size: 46px;
      line-height: 56px;
      color: #4a4a4a;
      font-family: "Axiforma";
      font-weight: bold;
      font-style: normal;
    }

    h5 {
      margin: 0 0 20px 0;
      font-size: 18px;
      line-height: 28px;
      font-family: "Axiforma";
      font-weight: 500 !important;
      font-style: normal;
      // color: #b7b7b7;
    }
    h5 span {
      color: #7a7a7a;
    }
    a {
      position: relative;
      display: inline-block;
      padding: 0 0 0 25px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      font-size: 18px;
      line-height: 28px;
      text-decoration: underline;
      font-family: "Axiforma";
      font-weight: 500 !important;
      font-style: normal;
      color: #7a7a7a;
      text-align: right;
      &::after {
        content: "";
        position: absolute;
        vertical-align: middle;
        left: 0;
        top: 3px;
        width: 17px;
        height: 20px;
        background: transparent url(../../assets/pdf.png) no-repeat left top;
      }
      &:hover {
        color: #0bb3a2;
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .selected-product-details_left h2 {
    text-align: center;
  }
  .selected-product-details_right-content .MuiButton-containedSecondary {
    margin-top: 0px;
    margin-left: -5px;
  }
  .img_padding {
    img {
      width: 100%;
      height: auto;
    }
  }
}
@media only screen and (max-width: 768px) {
  .selected-product-details_left .img_padding {
    display: block;
    justify-content: center;
    align-items: center;
  }
  .selected-product-details_left .img_padding .selected-product-details_right {
    width: 60%;
    margin: 0 auto;
  }
}
.selected-product-details_left {
  border-radius: 10px;
  padding: 30px 30px 0 30px;
  position: relative;
}

.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
  color: #fff !important;
  border: 2px solid #0bb3a2 !important;
  background-color: #ffffff !important;
}

.MuiAlert-filledSuccess {
  color: #fff !important;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #0bb3a2 !important;
}

.alice-carousel__dots {
  position: absolute !important;
  width: 100% !important;
  bottom: -68px !important;
  margin: 0 auto !important;
}

.add_to_cart {
  border-radius: 0px;
  margin: 30px 0 0 0;
  padding: 7px 50px;
}

.alice-carousel__stage-item {
  vertical-align: middle !important;
  text-align: center !important;
  height: auto !important;
  // img {
  //   height: auto !important;
  //   width: 400px !important;
  //   display: block !important;
  //   margin: 0 auto !important;
  //   cursor: pointer !important;
  // }
}

.slider_icons {
  font-size: 25px;
  border: 1px solid;
  border-radius: 50%;
  color: #0bb3a2;
  cursor: pointer;
}

.slider_icons_disabled {
  font-size: 25px;
  border: 1px solid;
  border-radius: 50%;
  color: #a0a0a0;
  cursor: pointer;
}

.alice-carousel__play-btn {
  top: 0 !important;
  left: 0 !important;
  color: #0bb3a2 !important;
  padding: 5px 10px !important;
  background-color: #eee !important;
  border-radius: 5px !important;
}

.alice-carousel__slide-info {
  top: 0 !important;
  right: 0 !important;
  color: #0bb3a2 !important;
  background-color: #eee !important;
}

.alice-carousel__next-btn {
  position: absolute !important;
  top: 40% !important;
  left: 105% !important;
}

.alice-carousel__prev-btn {
  position: absolute !important;
  left: -10% !important;
  top: 40% !important;
  text-align: left !important;
}
.selected-product-details_right {
  padding-top: 25%;
}
@media only screen and (max-width: 992px) {
  .selected-product-details_right {
    padding-top: 0;
  }
}

.image-gallery {
  padding: 25px;
  border: 2px solid #ccc;
}

.image-gallery-slide .image-gallery-image {
  width: 70%;
  display: block;
  margin: 0 auto;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  width: 70%;
  display: block;
  margin: 0 auto;
}

.image-gallery-thumbnail {
  width: 15% !important;
  height: 15% !important;
}
.image-gallery-thumbnail:hover {
  border: 3px solid #0bb3a2 !important;
  // padding: 10px;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  padding: 10px;
  border: 3px solid #0bb3a2 !important;
}

.pswp--ui-visible .pswp__hide-on-close {
  font-family: "Axiforma";
  font-weight: 300;
  font-style: normal;
  font-size: 18px !important;
}

// Popup IMG
.pswp__zoom-wrap {
  // transform: translate3d(26rem, 100px, 0px) scale3d(1, 1, 1) !important;
  position: absolute !important;
  top: 13% !important;
  left: 5% !important;
  .pswp__img {
    // width: 250px !important;
    // height: auto !important;
    // transform: none !important;

    width: auto !important;
    height: 350px !important;
  }
}
