.cart-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  .item-details {
    text-align: left;
    display: flex;
    justify-content: space-between;
    width: 95%;
    // padding: 20px 10px;
    align-items: center;
    .name {
      font-size: 12px;
      line-height: 16px;
      color: #7e7e7e;
      font-family: "Axiforma";
      font-weight: 300;
      font-style: normal;
      margin-bottom: 10px;
      display: block;
      padding: 0px 5px;
      width: 55%;
      cursor: pointer;
    }
    .quantity_number {
      font-size: 13px;
      line-height: 16px;
      color: #7e7e7e;
      font-family: "Axiforma";
      font-weight: 300;
      font-style: normal;
      margin-bottom: 7px;
      display: block;
      padding: 0px 10px;
    }
    .icons_in_cart_delete {
      font-size: 20px;
      margin-top: -15px;
      cursor: pointer;
    }
    .cart_dropdown_quantity {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 25%;
      .icons_in_cart {
        font-size: 25px;
        margin-top: -10px;
        cursor: pointer;
      }
    }
  }
}
