.check h1 {
  font-size: 60px;
  line-height: 58px;
  margin-bottom: 20px;
  color: #0bb3a2;
  margin-top: 30px;
  text-align: center;
  font-family: "Axiforma";
  font-weight: bold;
  font-style: normal;
}
.product {
  margin-bottom: 40px;
  padding-bottom: 40px;
  text-align: left;
  border-bottom: 1px solid #a9a9a9;
  .back_button {
    display: inline-block;
    font-size: 21px;
    line-height: 31px;
    color: #fff;
    font-family: "Axiforma";
    font-weight: bold;
    font-style: normal;
    border: 1px solid #11b7a6;
    cursor: pointer;
    background-color: #11b7a6;
    border-radius: 5px;
    padding: 7px 30px;
    margin: 0 30px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    span {
      display: inline-block;
      position: relative;
      padding-right: 10px;
      color: #fff;
    }
    &:hover {
      border: 1px solid #0bb3a2;
      background-color: #ffffff;
      color: #0bb3a2;
      span {
        left: -5px;
        color: #0bb3a2;
      }
    }
  }
  p {
    color: #909090;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    border-bottom: 1px solid darkgray;
    margin-bottom: 0px;
    padding: 20px 0px;
    font-family: "Axiforma";
    font-weight: 500;
    font-style: normal;
    text-transform: capitalize;
    cursor: pointer;
  }
  span {
    color: #0bb3a2;
    padding-right: 10px;
    cursor: pointer;
  }
}

.form-controls {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #ddd;
  margin-bottom: 60px;
  label {
    font-size: 20px;
    line-height: 30px;
    font-family: "Axiforma";
    font-weight: bold;
    font-style: normal;
    color: #666666;
    text-align: left;
    margin-bottom: 10px;
  }
  .field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .control {
    position: relative;
    width: 100%;
    .country_prefixes {
      position: absolute;
      top: 47%;
      left: 5%;
      font-family: "Axiforma";
      font-weight: 500;
      font-style: normal;
      border-right: 1px solid rgb(184, 184, 184);
      padding-right: 5px;
      height: 22px;
      color: #0bb3a2;
    }
    .type_tel {
      // padding-left: 15%;
    }
  }

  .form_icons {
    position: absolute;
    top: 50%;
    left: 1rem;
    font-size: 22px;
    transform: translateY(-100%);
    color: #444;
    pointer-events: none;
  }
  .textarea_icon {
    position: absolute;
    top: 32px;
    left: 1rem;
    font-size: 1.6rem;
    transform: translateY(-62%);
    color: #444;
    pointer-events: none;
  }
  .control:not(:last-child) {
    margin-right: 1rem;
  }

  .field input,
  .message textarea {
    font-family: "Axiforma";
    font-weight: 300;
    font-style: normal;
    width: 100%;
    height: 100%;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 13px 10px;
    // text-indent: 1rem;
    color: #444;
    box-shadow: #ccc 0px 0px 10px -3px;
    font-size: 18px;
    line-height: 28px;
  }

  .field ion-icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    font-size: 1.6rem;
    transform: translateY(-50%);
    color: #444;
    pointer-events: none;
  }

  .message {
    position: relative;
  }

  .message textarea {
    min-height: 20rem;
    padding: 1rem 0;
    resize: vertical;
    padding: 1rem 1rem;
    height: 20px;
  }

  .message ion-icon {
    position: absolute;
    top: 1.3rem;
    left: 1.5rem;
    font-size: 1.6rem;
    color: #444;
    pointer-events: none;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    text-align: center;
  }

  .checkout_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .check_to_send {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      svg {
        color: #0bb3a2;
      }
      p {
        width: 75%;
        font-family: "Axiforma";
        font-weight: 300;
        font-style: normal;
        font-size: 16px;
        color: #777;
        margin-top: 15px;
        a {
          color: #0bb3a2;
          font-family: "Axiforma";
          font-weight: 500;
          font-style: normal;
        }
      }
    }
    .sendMessageBtn {
      display: inline-block;
      font-size: 21px;
      width: 25%;
      line-height: 31px;
      color: #fff;
      font-family: "Axiforma";
      font-weight: bold;
      font-style: normal;
      border: 1px solid #11b7a6;
      background-color: #11b7a6;
      border-radius: 5px;
      padding: 7px 30px;
      // margin: 0 30px 0 0;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      text-decoration: none;
      margin-top: 10px;
      &:hover {
        background-color: #fff;
        border: 1px solid #0bb3a2;
        color: #0bb3a2;
      }
    }
  }
}
.wrapper_contactform .heading {
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  font-family: "Axiforma";
  font-weight: bold;
  font-style: normal;
  color: #00b2a0;
  display: block;
  padding: 20px 0;
  margin-bottom: 30px;
}
@media (max-width: 567px) {
  .wrapper_contactform .heading {
    font-size: 2.5rem;
  }
  .form-controls {
    margin-bottom: 30px;
  }
  .field {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .form-controls .control:not(:last-child) {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }

  .buttons {
    flex-direction: column;
  }

  .buttons input[type="submit"] {
    margin-top: 2rem;
    padding: 1rem 0;
    font-size: 1.5rem;
  }

  .field input,
  .message textarea {
    padding: 1rem 0;
    text-indent: 3.5rem;
    font-size: 1.5rem;
  }
  .ReactModal__Content {
    width: 90%;
  }
}

.optional_field {
  color: #ccc;
  font-size: 18px;
}

.email_sent_checker {
  display: flex;
  align-items: center;
  gap: 15px;
  .ReactModal__Overlay {
    z-index: 99;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Content--before-close {
    transform: translate(-50%, -50%) scale(0) !important;
  }
  p {
    font-family: "Axiforma";
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    color: #ffffff;
    margin-top: 17px;
  }
  svg {
    font-size: 22px;
    color: #fff;
    cursor: pointer;
  }
  &:hover {
    svg {
      color: rgb(225, 225, 225);
    }
  }
}

.react-tel-input .form-control {
  margin-bottom: 20 !important;
  padding-left: 45px !important;
}
